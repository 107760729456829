import { Component } from '@angular/core';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

/**
 * Footer component for DKS
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  /** year */
  year = this.utilService.getYear()
  /** version */
  version = environment.appVersion

  /**
   * Constructor
   * @param utilService Utilities service
   */
  constructor(private utilService: UtilsService) { }
}
