/**
 * @ignore
 */
export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  firebase: {
    apiKey: "AIzaSyDk4aWq5iWTMnAIhtZf5PujU4YRyaGow-w",
    authDomain: "dancekidsstudio.firebaseapp.com",
    databaseURL: "https://dancekidsstudio.firebaseio.com",
    projectId: "dancekidsstudio",
    storageBucket: "dancekidsstudio.appspot.com",
    messagingSenderId: "123027499750",
    appId: "1:123027499750:web:f969523ef446b0610f6927"
  }
}
