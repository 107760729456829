import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TransferState } from '@angular/platform-browser';
import { Observable, startWith, tap } from 'rxjs';
import { SeoService } from './seo.service';

/**
 * Firebase service to provide efficient access to Firebase data (CRUD)
 */
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  /** date */
  date = 0;
  /** root */
  root = 'https://dancekids.web.app/'

  /**
   * Constructor
   * @param db Angular Fire Database
   * @param seoService Seo Service
   * @param afs Angular Firestore
   * @param state Transfer state
   */
  constructor(
    private db: AngularFireDatabase,
    private seoService: SeoService,
    private afs: AngularFirestore,
    private state: TransferState
  ) { }

  /**
   * Add data to database
   * @param type Type (location)
   * @param data Data to add
   */
  addData(type: string, data: any): void {
    this.date = new Date().getTime()
    data.uid = this.date.toString()
    const obj = this.db.database.ref('/' + type + '/' + data.uid)
    obj.set(data)
  }

  /**
   * Add data by key
   * @param type Type (location)
   * @param data Data to add
   */
  addByKey(type: string, data: any): void {
    const obj = this.db.database.ref('/' + type)
    obj.set(data)
  }

  /**
   * Edit Data
   * @param key File key
   * @param type Type (location)
   * @param data Data to update/edit
   */
  editData(key: string, type: string, data: any) {
    const edit = this.db.database.ref('/' + type + '/' + key)
    edit.update(data)
  }

  /**
   * Edit data without a key
   * @param type Type (location)
   * @param data Data to update/edit
   */
  editDataNoKey(type: string, data: any) {
    const edit = this.db.database.ref('/' + type)
    edit.update(data)
  }

  /**
   * Get data
   * @param listPath Location of data to retrieve
   */
  getData(listPath: string): Observable<any[]> {
    return this.db.list(listPath).valueChanges()
  }

  /**
   * Delete data
   * @param key Key
   * @param type Type (location)
   */
  deleteData(key: string, type: string): void {
    this.db.list(type).remove(key)
  }

  /**
   * Get ordered data
   * @param path Location to retrieve data from
   */
  getOrdered(path: string): Observable<any[]> {
    return this.db.list(path, ref => ref.orderByChild('index')).valueChanges()
  }

  /**
   * Server side rendered Firestore Doc
   * @param key Key
   * @param listPath Location 
   * @param tags Tags to trigger image
   */
  ssrFirestoreDoc(key: any, listPath: string, tags?: any) {
    const exists = this.state.get(key, [] as any)
    let image = ''
    const description = 'An award winning dance studio in Lebanon, OH offering tap, jazz, ballet, lyrical, contemporary, and hip-hop.'
    return this.db.list(listPath).valueChanges()
      .pipe(
        tap((pageData: any) => {
          this.state.set(key, pageData)
          if (tags) {
            switch (tags.title.toLowerCase()) {
              case 'home':
                image = pageData[0].welcome.imageUrl
                break
              case 'classes':
                image = pageData[0].imageUrl
                break
              default:
                image = `${this.root}assets/images/logos/dks-dancer-square.png`
                break
            }
            this.seoService.generate({
              title: `Dancekids Studio - ${tags.title}`,
              image: tags.image ? tags.image : image,
              description: tags.description ? tags.description : description,
              path: tags.title.toLowerCase() === 'home' ? '' : tags.title.toLowerCase(),
              root: this.root
            })
          }
        }),
        startWith(exists)
      )
  }

  /**
   * Server side rendered Firestore Doc Ordered
   * @param key Key
   * @param listPath Location 
   * @param tags Tags to trigger image
   */
  ssrFirestoreDocOrdered(key: any, listPath: string, tags?: any) {
    const exists = this.state.get(key, [] as any)
    let image = ''
    const description = 'An award winning dance studio in Lebanon, OH offering tap, jazz, ballet, lyrical, contemporary, and hip-hop.'
    return this.db.list(listPath, ref => ref.orderByChild('index')).valueChanges()
      .pipe(
        tap((pageData: any) => {
          this.state.set(key, pageData)
          if (tags) {
            switch (tags.title.toLowerCase()) {
              case 'home':
                image = pageData[0].welcome.imageUrl
                break
              case 'classes':
                image = pageData[0].imageUrl
                break
              default:
                image = `${this.root}assets/images/logos/dks-dancer-square.png`
                break
            }
            this.seoService.generate({
              title: `Dancekids Studio - ${tags.title}`,
              image: tags.image ? tags.image : image,
              description: tags.description ? tags.description : description,
              path: tags.title.toLowerCase() === 'home' ? '' : tags.title.toLowerCase(),
              root: this.root
            })
          }
        }),
        startWith(exists)
      )
  }
}
