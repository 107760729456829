<footer class="footer-wrapper">
  <div>
    &copy; Dancekids Studio {{ year }}
    <br />
    <span class="watermark">(v.{{ version }})</span>
  </div>
  <div class="no-small social">
    <a href="https://www.facebook.com/DancekidsStudioLebanon/">
      <img
        class="filter-primary"
        src="./../../../../assets/images/stock/facebook-bg.svg"
        alt="facebook" />
    </a>
    <a href="https://www.instagram.com/dancekidsstudio/" target="_blank">
      <img
        class="filter-primary"
        src="./../../../assets/images/stock/instagram-bg.svg"
        alt="instagram" />
    </a>
  </div>
  <div>
    <!-- <a href='tel:+15139340909'>(513) 934 - 0909</a> -->
    <address>
      726 E. Main St.
      <br />
      Lebanon, OH 45036
    </address>
    <img
      class="none"
      src="./../../../assets/images/logos/dks-dancer-square.png"
      alt="logo" />
    <img
      class="none"
      src="./../../../assets/icons/favicon-32x32.png"
      alt="logo" />
  </div>
</footer>
