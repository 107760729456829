import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { User } from './user';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { FirebaseService } from '../shared/services/firebase.service';
import { isPlatformBrowser } from '@angular/common';

/**
 * Auth servcie for DKS
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /** userData */
  userData: User = {
    uid: '',
    email: '',
    photoURL: '',
    displayName: '',
    emailVerified: false,
    phoneNumber: '',
    roles: {
      reader: true
    },
  }
  
  /** username */
  username: string | null = null
  /** user */
  user$: Observable<User | null | undefined>;

  /** userDisplayName */
  userDisplayName: string | null = null
  /** loggedIn */
  loggedIn = false;

  /** only for admin use */
  private usersCollection: AngularFirestoreCollection<User> | null = null
  /** users */
  users: Observable<User[]> | null = null

  /**
   * Constructor
   * @param afs Angular Firestore
   * @param afAuth Angular Fire Auth
   * @param router Angular Router
   * @param ngZone NgZone
   * @param fbService Firebase service
   * @param platform Platform
   */
  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    private fbService: FirebaseService,
    @Inject(PLATFORM_ID) private platform: Object
  ) {
    //// Get auth data, then get firestore user document || null
    this.user$ = this.afAuth.authState
      .pipe(switchMap((user) => {
        if (user) {
          this.setLoggedIn(true)
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
        } else {
          return of(null)
        }
      }))


    this.afAuth.authState.subscribe((user: any) => {
      if (user) {
        this.userData = user
        if (!this.username) {
          this.username = user.displayName
          this.setUDN(user.displayName)
        }
        localStorage.setItem('user', JSON.stringify(this.userData))
        this.setLoggedIn(true)
      } else {
        localStorage.setItem('user', '')
        const lsData = localStorage.getItem('user') || null
        lsData ? JSON.parse(lsData) : console.log('')
      }
    })
  }

  /**
   * Set User Display Name
   * @param x User Display Name
   */
  setUDN(x: string | null): void {
    this.userDisplayName = x
  }
  /**
   * Get User Display Name
   */
  getUDN() {
    return this.userDisplayName
  }
  /**
   * Set Logged In status
   * @param x Status (boolean)
   */
  setLoggedIn(x: boolean): void {
    this.loggedIn = x
  }
  /**
   * Get Logged In status
   */
  getLoggedIn() {
    return this.loggedIn
  }

  /**
  * Returns true when user is logged in.
  */
  get isLoggedIn(): boolean {
    const lsData = localStorage.getItem('user') || ''
    const user = lsData === '' ? null : JSON.parse(lsData)
    return user ? true : false
  }

  /**
   * Get User Data
   */
  async getUserData(): Promise<User> {
    return this.userData
  }

  /**
   * Sign in with email/password
   * @param email Email address
   * @param password Password
   */
  SignIn(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.SetUserData(result.user)
        this.setLoggedIn(true)
      })
      .catch((error) => {
        this.fbService.addData('errorMessages', error.message)
      });
  }

  /**
   * Sign up with email/password
   * @param email Email address
   * @param password Password
   */
  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail()
        this.SetUserData(result.user)
      })
      .catch((error) => {
        this.fbService.addData('errorMessages', error.message)
      });
  }

  /**
   * Send email verification when new user sign up
   */
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['verify-email-address'])
      });
  }

  /**
   * Reset Forgot password
   * @param passwordResetEmail Email to send password reset to
   */
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        if (isPlatformBrowser(this.platform)) {
          window.alert('Password reset email sent, check your inbox.')
        }
      })
      .catch((error) => {
        this.fbService.addData('errorMessages', error.message)
      });
  }

  /**
   * Sign in with Google
   */
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
      if (res) {
        this.setLoggedIn(true)
      }
    })
  }

  /**
   * Auth logic to run auth providers
   * @param provider Provider
   */
  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.SetUserData(result.user)
        this.setLoggedIn(true)
      })
      .catch((error) => {
        this.fbService.addData('errorMessages', error.message)
      });
  }

  /**
   *  Setting up user data when sign in with username/password, 
   * sign up with username/password and sign in with social auth  
   * provider in Firestore database using 
   * AngularFirestore + AngularFirestoreDocument service 
   */
  SetUserData(user: any) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName || '',
      photoURL: user.photoURL || '',
      emailVerified: user.emailVerified || false,
      phoneNumber: user.phoneNumber || '',
      roles: {
        reader: true
      },
    };
    localStorage.setItem('user', JSON.stringify(userData))
    return userRef.set(userData, {
      merge: true,
    });
  }

  /**
   * Sign Out
   */
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user')
      this.setLoggedIn(false)
      this.ngZone.run(() => {
        this.router.navigate(['login'])
      })

    });
  }

  /**
   * Verify if user can read data
   * @param user User to verify
   */
  canRead(user: User): boolean {
    const allowed = ['admin', 'editor', 'reader']
    return this.checkAuthorization(user, allowed)
  }

  /**
   * Verify if user can edit data
   * @param user User to verify
   */
  canEdit(user: User): boolean {
    const allowed = ['admin', 'editor']
    return this.checkAuthorization(user, allowed)
  }

  /**
   * Verify if user can delete data
   * @param user User to verify
   */
  canDelete(user: User): boolean {
    const allowed = ['admin']
    return this.checkAuthorization(user, allowed)
  }

  /**
   * Determines if user has matching role
   * @param user User to verify
   * @param allowedRoles Role(s) to check against
   */
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false
    for (const role of allowedRoles) {
      if (user.roles[role as keyof typeof user.roles]) {
        return true
      }
    }
    return false
  }

  /**
   * Get User data returned stringified
   */
  getUserDataStringified(): string {
    return JSON.stringify(this.userData)
  }

  /**
   * Get all users
   */
  getAllUsers(): Observable<User[]> {
    this.usersCollection = this.afs.collection<User>('users');
    this.users = this.usersCollection.valueChanges();
    return this.users;
  }

  /**
   * Set user role
   * @param user User data to update with role
   * @param isAdmin Admin role
   * @param isReader Reader role
   * @param isEditor Editor role
   * @param isTeacher Teacher role
   */
  setUserRoles(user: User, isAdmin?: boolean, isReader?: boolean, isEditor?: boolean, isTeacher?: boolean) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data: User = {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified || false,
      roles: {
        admin: isAdmin,
        reader: isReader,
        editor: isEditor,
        teacher: isTeacher
      }
    };
    return userRef.set(data, { merge: true });
  }

  /**
   * Set user as admin
   * @param user User to set as admin
   * @param isAdmin Value to set (boolean)
   */
  setUserAdmin(user: User, isAdmin: boolean) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const data: User = {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified || false,
      roles: {
        admin: isAdmin
      }
    };
    return userRef.set(data, { merge: true });
  }
}

