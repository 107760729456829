import { Injectable, StateKey, TransferState } from '@angular/core';
import { getFunctions, httpsCallable } from '@firebase/functions';
import { Schedule } from '../interfaces/interfaces';
import { UtilsService } from './utils.service';

/**
 * Jackrabbit service for DKS
 */
@Injectable({
  providedIn: 'root'
})
export class JackrabbitService {
  /** class data */
  classData: Array<Schedule> | undefined

  /**
   * Constructor
   * @param state Transfer state
   * @param utilsService Utilities service
   */
  constructor(private state: TransferState, private utilsService: UtilsService) { }

  /**
   * Set class data
   * @param data Data to set as classData
   */
  setClassData(data: Schedule[]): void {
    this.classData = data
  }

  /**
   * Retrieve class data
   */
  getClassData(): Schedule[] | undefined {
    return this.classData
  }

  /**
   * Fetch class data
   * @param key Key to use to get/set value of state
   */
  fetchClassData = (key: StateKey<any>): Schedule[] | undefined | Promise<void | Schedule[]> => {
    const exists = this.state.get(key, {} as any)
    if (!this.utilsService.isEmpty(exists) && !this.classData) {
      this.setClassData(exists)
      return exists
    } else if (!this.classData) {
      const functions = getFunctions()
      const jrFunction = httpsCallable(functions, 'fetchJRData')
      return jrFunction({})
        .then((result) => {
          const data = result.data as any
          const schedule = data.data as Schedule[]
          this.state.set(key, schedule)
          this.setClassData(schedule)
          return schedule
        })
        .catch((error) => {
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.error('error', code, message, details)
        })
    }
    return this.classData
  }
}
