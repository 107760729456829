import { Injectable } from '@angular/core';

/**
 * Utilities service for DKS
 */
@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  /**
   * Get current year
   */
  getYear() {
    return new Date().getFullYear()
  }
  
  /**
   * Check if object is empty
   * @param obj Object to check
   */
  isEmpty(obj: object) {
    return Object.keys(obj).length === 0
  }
}
