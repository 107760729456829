<ng-container *ngFor='let alert of alertsObservable | async'>
  {{alert}}
  <ng-container *ngIf='alert.status && checkExpiration(alert.expire) && display && !alertAcknowledged'>
    <div class='modal-background'>
      <div class='modal'>
        <button class='btn' (click)='closeModal()'>Close</button>
        <h2>{{alert.title}}</h2>
        <pre>{{alert.message}}</pre>
        <p *ngIf='alert.contactOn && alert.contact'>Contact us at <a [href]='"mailto:" + alert.contact + "?subject=Inquiry from website"' target='_blank'>{{alert.contact}}</a>  for more information</p>
      </div>
    </div>
  </ng-container>
</ng-container>
