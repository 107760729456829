import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, OnInit, PLATFORM_ID, makeStateKey } from '@angular/core'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { JackrabbitService } from './shared/services/jackrabbit.service'
import { Schedule } from './shared/interfaces/interfaces'
import { TransferState } from '@angular/platform-browser'
import { NavigationEnd, Router } from '@angular/router'

/** SCHED_KEY for Universal */
const SCHED_KEY = makeStateKey<any>('schedule')

/**
 * App component for DKS
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  /** title */
  title = 'dks';

  /** isBrowser */
  isBrowser = false

  /**
   * Constructor
   * @param platform Platform ID
   * @param jackrabbitService Jackrabbit service
   * @param transferState Transfer State
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private jackrabbitService: JackrabbitService,
    private router: Router,
    private transferState: TransferState) { 
      if (isPlatformBrowser(this.platformId)) {
        this.isBrowser = true
      }
    }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd) || !this.isBrowser) {
        return
      }
      window.scrollTo(0, 0)
    })
    const functions = getFunctions()
    const jrFunction = httpsCallable(functions, 'fetchJRData')
    jrFunction({})
      .then((result) => {
        const data = result.data as any
        const schedule = data.data as Schedule[]
        this.transferState.set(SCHED_KEY, schedule)
        this.jackrabbitService.setClassData(schedule)
      })
      .catch((error) => {
        const code = error.code;
        const message = error.message;
        const details = error.details;
        console.error('error', code, message, details)
      })
  }
}
