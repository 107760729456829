import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, take, tap } from 'rxjs';
import { AuthService } from './auth.service';

/**
 * Admin Guard for DKS
 */
@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  /**
   * Constructor
   * @param auth Authentication service
   * @param router Angular router
   * @param ngZone NgZone
   */
  constructor(private auth: AuthService,
    public router: Router,
    public ngZone: NgZone
  ) { }

  /**
   * Can Activate (if not admin, route to 404)
   */
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.auth.user$.pipe(
      take(1),
      map(user => user && user.roles.admin ? true : false),
      tap(isAdmin => {
        if (!isAdmin) {
          this.router.navigate(['four-oh-four'])
          console.error('Access denied - Admins only');
        }
      })
    );
  }
}
