import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseService } from 'src/app/shared/services/firebase.service';

/**
 * Alert component for showing current alerts on visit
 */
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  /** alertsObservable */
  alertsObservable: Observable<any[]> | undefined
  /** display */
  display = true
  /** boolean to indicate if in browser or not */
  isBrowser = false
  /** boolean to indicate if alert has been acknowledged or not */
  alertAcknowledged = false

  /**
   * Constructor
   * @param fbService Service for connecting to Firebase
   */
  constructor(
    private fbService: FirebaseService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    if (isPlatformBrowser(this.platformId) && window?.sessionStorage) {
      this.isBrowser = true;
      this.checkAcknowledged();
    }
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    this.getAlert()
  }

  /**
   * Get cookie by name
   * @param cName Name of cookie
   */
  getCookie = (cName: string): string => {
    if (this.isBrowser) {
      const name = `${cName}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';')
      for (const attribute of ca) {
        let c = attribute;
        while (c.startsWith(' ')) {
          c = c.substring(1)
        }
        if (c.startsWith(name)) {
          return c.substring(name.length, c.length)
        }
      }
      return '';
    } else {
      return '';
    }
  }

  checkAcknowledged = () => {
    if (!this.alertAcknowledged) {
      this.alertAcknowledged = this.getCookie('alertConfirmation') === 'true'
    }
  }

  /**
   * Retrieve alert observable from firebase service
   */
  getAlert = async()  => {
    this.alertsObservable = this.fbService.getData('alerts');
  }

  /**
   * Check if alert is expired
   * @param expire - String date of expiration
   * @returns {boolean}
   */
  checkExpiration(expire: string): boolean {
    if (!expire) {
      return true
    }
    const now = new Date()
    const expirationDate = new Date(expire)
    now.setHours(0, 0, 0, 0)
    expirationDate.setHours(24, 0, 0, 0)

    if (now.toISOString() > expirationDate.toISOString()) {
      this.display = false
      return false
    }

    return true
  }

  /**
   * Close alert modal
   */
  closeModal(): void {
    if (this.isBrowser) {
      document.cookie = 'alertConfirmation=true; expires=session; path=/; secure'
    }
    this.display = false
  }
}
