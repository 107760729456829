import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './core/admin.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule) },
  { path: 'home', redirectTo: '' },
  { path: 'admin', loadChildren: () => import('./routes/admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuard] },
  { path: 'login', loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule) },
  { path: 'classes', loadChildren: () => import('./routes/classes/classes.module').then(m => m.ClassesModule) },
  { path: 'pricing', loadChildren: () => import('./routes/pricing/pricing.module').then(m => m.PricingModule) },
  { path: 'staff', loadChildren: () => import('./routes/staff/staff.module').then(m => m.StaffModule) },
  { path: 'contact', loadChildren: () => import('./routes/contact/contact.module').then(m => m.ContactModule) },
  { path: 'gallery', loadChildren: () => import('./routes/gallery/gallery.module').then(m => m.GalleryModule) },
  { path: 'nutcracker', loadChildren: () => import('./routes/events/events.module').then(m => m.EventsModule)},
  { path: 'recital', loadChildren: () => import('./routes/recital/recital.module').then(m => m.RecitalModule)},
  { path: 'events', redirectTo: 'nutcracker' },
  { path: '**', loadChildren: () => import('./routes/four-oh-four/four-oh-four.module').then(m => m.FourOhFourModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
