import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser'

/**
 * SEO service for DKS
 */
@Injectable({
  providedIn: 'root'
})
export class SeoService {

  /**
   * Constructor
   * @param meta Meta
   * @param titleService Title service
   */
  constructor(
    private meta: Meta,
    private titleService: Title
  ) { }

  /**
   * Generate tags
   * @param tags Values to add
   */
  generate(tags: any) {
    const tagset = {
      title: 'Dancekids Studio',
      description: 'An award winning dance studio in Lebanon, OH offering tap, jazz, ballet, lyrical, contemporary, and hip-hop.',
      image: 'assets/images/logos/dks-dancer-square.png',
      path: '',
      slug: '',
      root: 'https://dancekids.web.app/',
      ...tags
    }
    // set title
    this.titleService.setTitle(tagset.title)

    // set meta tags
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' })
    this.meta.updateTag({ name: 'twitter:site', content: 'Dancekids Studio' })
    this.meta.updateTag({ name: 'twitter:title', content: tagset.title })
    this.meta.updateTag({ name: 'twitter:description', content: tagset.description })
    this.meta.updateTag({ name: 'twitter:image', content: tagset.image })

    this.meta.updateTag({ name: 'description', content: tagset.description })
    this.meta.updateTag({ name: 'image', content: tagset.image })
    
    this.meta.updateTag({ property: 'og:type', content: 'article' })
    this.meta.updateTag({ property: 'og:site_name', content: 'Dancekids Studio' })
    this.meta.updateTag({ property: 'og:title', content: tagset.title })
    this.meta.updateTag({ property: 'og:description', content: tagset.description })
    this.meta.updateTag({ property: 'og:image', content: tagset.image })
    // todo: Update this url
    this.meta.updateTag({ property: 'og:url', content: `${tagset.root}${tagset.path}` })
  }

}
